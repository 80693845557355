<template>
  <div class="details_container">
    <div class="header" v-if="showHeader">
      <van-nav-bar
        title="文章"
        left-arrow
        right-text="审核管理"
        @click-right="handleReviewed"
        @click-left="handleBlack"
        safe-area-inset-top
      >
      </van-nav-bar>
    </div>

    <div class="handle-article">
      <div @click="handleAtricleEditor">
        <icon-svg iconClass="iconedit-3" class="editor" />编辑
      </div>
    </div>

    <div class="article-details-container">
      <div class="article-info">
        <span class="company">所属公司</span>
        <span class="company-name">{{ article.createMerchantName }}</span>
      </div>
      <div class="article-info">
        <span class="company">创建人</span>
        <span class="company-name">{{ article.createName }}</span>
      </div>
      <div class="article-title">
        {{ article.title }}
      </div>

      <!-- <div class="cover-img">
        <img :src="article.image" alt="" @click="loadCoverImage" />
      </div> -->
      <div class="artcle-content">
        <div class="artcle-content-box">
          <transform-html :contents="article.contents"></transform-html>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getArticleById } from "../../api/articleManagement";
import transformHtml from "../../components/transformHTML.vue";
import { searchFileList } from "../../api/file";
import { ImagePreview } from "vant";
export default {
  components: {
    transformHtml,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      HeadRightText: "",
      morenImage: "",
      coverImage: [],
      articleId: route.query.id,
      article: {
        createMerchantId: "",
        createId: "",
        nameCard: "",
        title: "",
        contents: "",
        createTime: null,
        star: null,
        collection: null,
        image: "",
        createMerchantName: "",
        createName: "",
      },
      fileParams: {
        busiId: route.query.id,
        busiCat: "article",
        groupId: "articleIndexPic",
      },
    });
    let showHeader = computed(() => route.query.hideTitleBar != 1);
    getArticleById(state.articleId).then((data) => {
      state.article.title = data.title;
      state.article.createTime = data.createTime
        ? data.createTime.slice(0, 10)
        : "";
      state.article.contents = data.content;
      state.article.star = data.countOfLikes;
      state.article.collection = data.countOfCollection;
      state.article.createMerchantId = data.createMerchantId;
      state.article.createId = data.createId;
      state.article.createMerchantName = data.createMerchantName;
      state.article.createName = data.createName;
    });
    //查找文章封面图
    searchFileList(state.fileParams).then((data) => {
      // console.log("文章封面图",data[0].url);
      if (data[0]) {
        state.article.image = `${process.env.VUE_APP_WATCH_FILE_API}/${data[0].url}`;
        state.coverImage[0] = `${process.env.VUE_APP_WATCH_FILE_API}/${data[0].url}`;
      } else {
        state.article.image = state.morenImage;
        state.coverImage[0] = state.morenImage;
      }
    });
    //封面图
    const loadCoverImage = () => {
      ImagePreview({
        images: state.coverImage,
        showIndex: false,
      });
    };
    //返回
    const handleBlack = () => {
      if (route.query.isSearch === "isSearch") {
        router.push({
          path: "/articleManagement/search",
          query: {
            oneType: route.query.oneType,
            twoType: route.query.twoType,
            searchVal: route.query.searchVal,
            artType: route.query.artType,
          },
        });
      } else {
        router.push({
          name: "articleManagement",
          params: {
            oneType: route.query.oneType ? route.query.oneType : "",
            twoType: route.query.twoType ? route.query.twoType : "",
            typesStr: route.query.typesStr ? route.query.typesStr : "",
          },
        });
      }
    };
    const handleReviewed = () => {
      router.push({
        path: "/articleManagement/reviewed",
        query: { id: state.articleId },
      });
    };
    const handleAtricleEditor = () => {
      router.push({
        path: "/articleVisitors/visitorEditor",
        query: {
          id: route.query.id,
          type: route.query.artType,
          oneType: route.query.oneType,
          twoType: route.query.twoType,
        },
      });
    };
    return {
      ...toRefs(state),
      handleReviewed,
      handleBlack,
      handleAtricleEditor,
      showHeader,
      loadCoverImage,
    };
  },
};
</script>

<style lang="scss">
.details_container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  .header {
    .van-nav-bar__arrow {
      color: #333333;
    }
    .van-nav-bar__title {
      color: #333333;
      font-weight: 600;
    }
  }
  .handle-article {
    position: fixed;
    right: 0;
    bottom: 110px;
    z-index: 100;
    div {
      height: 28px;
      width: 54px;
      color: #ffffff;
      font-size: 12px;
      opacity: 0.6;
      border-radius: 50px 0 0 50px;
      margin: 10px 0;
      box-sizing: border-box;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
    div:nth-child(1) {
      background: #ff6e01;
    }
  }
  .article-details-container {
    flex: 1;
    font-size: 14px;
    overflow-x: hidden;
    .article-info {
      font-weight: 600;
      height: 48px;
      padding-left: 16px;
      padding-right: 16px;
      box-sizing: border-box;
      line-height: 48px;
      border-bottom: 1px solid #eff0f3;
      .company {
        float: left;
      }
      .company-name {
        // position: absolute;
        // left: 50%;
        // transform:translateX(-50%);
        float: right;
      }
    }
    .article-title {
      text-align: left;
      font-weight: 600;
      font-size: 16px;
      padding: 0 16px;
      line-height: 22px;
      margin-top: 5px;
    }
    .cover-img {
      padding: 5px 16px;
      background-color: #fff;
      height: 160px;
      img {
        display: block;
        border-radius: 8px;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .artcle-content {
      width: 100%;
      padding: 0 16px;
      font-size: 14px;
      text-align: left;
      box-sizing: border-box;
      background-color: #ffffff;
      .artcle-content-box {
        img {
          width: 100%;
        }
        p {
          margin: 0;
          padding: 0;
        }
        border-bottom: 1px solid #f1f3f6;
      }
    }
  }
}
</style>